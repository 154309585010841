import { SET_ERROR, GET_CHILDREN, NEW_CHILD, GET_LIVE_CLASS } from '../types';

export default (state, action) => {
    switch (action.type) {
        case GET_CHILDREN:
            return {
                ...state,
                children: action.payload,
                loading: false
            };
        case NEW_CHILD:
            return {
                ...state,
                children: [...state.children, action.payload],
                loading: false
            };
        case GET_LIVE_CLASS:
            return {
                ...state,
                liveClass: action.payload,
                loading: false
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
};
