import React, { useReducer } from 'react';
import ChildrenContext from './childrenContext';
import childrenReducer from './childrenReducer';
import { SET_ERROR, GET_CHILDREN, NEW_CHILD, GET_LIVE_CLASS } from '../types';
import axios from 'axios';

const ChildrenState = (props) => {
    const initialState = {
        loading: true,
        children: [],
        liveClass: [],
        error: null
    };
    const [state, dispatch] = useReducer(childrenReducer, initialState);

    // Get Children
    const getChildren = async () => {
        try {
            const res = await axios.get('/childusers/');
            dispatch({ type: GET_CHILDREN, payload: res.data });
        } catch (error) {
            console.log(error.response.data.message);
            dispatch({ type: SET_ERROR });
        }
    };

    // Get Live Class details
    const getLiveClass = async () => {
        try {
            const res = await axios.get('/childusers/liveclass');
            dispatch({ type: GET_LIVE_CLASS, payload: res.data });
        } catch (error) {
            console.log(error.response.data.message);
            dispatch({ type: SET_ERROR });
        }
    };

    // Register a child
    const newChild = async (formData) => {
        try {
            const res = await axios.post('/childusers/register', formData);
            console.log(res.data);
            dispatch({ type: NEW_CHILD, payload: res.data });
        } catch (error) {
            console.log(error.response.data.message);
            dispatch({ type: SET_ERROR });
        }
    };

    // Update Screen Time
    const updateTime = async (time) => {
        try {
            const res = await axios.post('/time', time);
            console.log(res.data);
        } catch (error) {
            console.log(error.response.data.message);
            dispatch({ type: SET_ERROR });
        }
    };

    return (
        <ChildrenContext.Provider
            value={{
                loading: state.loading,
                children: state.children,
                error: state.error,
                liveClass: state.liveClass,
                getChildren,
                newChild,
                getLiveClass,
                updateTime
            }}
        >
            {props.children}
        </ChildrenContext.Provider>
    );
};

export default ChildrenState;
