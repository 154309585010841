import React from 'react';

function UtilGetStarted() {
    return (
        <div className="position-relative bg-white">
            <div
                style={{
                    width: '92.5%',
                    borderTopRightRadius: '20px',
                    borderBottomRightRadius: '20px',
                    minHeight: '300px',
                    position: 'absolute',
                    top: '20px',
                    left: '0'
                }}
                className="d-lg-none bg-brand-secondary-light"
            ></div>
            <div
                style={{
                    width: '49%',
                    borderTopRightRadius: '20px',
                    borderBottomRightRadius: '20px',
                    minHeight: '400px',
                    position: 'absolute',
                    top: '40px',
                    left: '0'
                }}
                className="d-none d-lg-block bg-brand-secondary-light"
            ></div>

            <div className="position-relative d-lg-flex">
                <div className="padding-y padding-x px-lg-5 col-lg-6 bg-ligh d-flex flex-column align-items-lg-end justify-content-lg-center">
                    <div>
                        <div className="max-width-450px">
                            <h2 className="h3 mb-3">
                                Try Astra Junior For Free
                            </h2>
                            <p>
                                Sign up today and start learning with a free
                                class. Enroll only once you are sure.
                            </p>
                        </div>

                        <div className="mt-3 mt-lg-4">
                            <a
                                href="/trial"
                                className="cta d-inline-block mr-3 bg-brand-secondary text-white"
                            >
                                Try A Free Class
                            </a>
                        </div>
                    </div>
                </div>

                <div className="padding-y padding-x px-lg-5 col-lg-6">
                    <div className="mb-5">
                        <div className="mb-3 mb-lg-4">
                            <img
                                style={{ maxWidth: '25px' }}
                                className="mb-3"
                                alt="generic icon representing the course curriculum"
                                src={require('./assets/util-get-started/curriculum.png')}
                            ></img>
                            <p className="open-sans-semi-bold mb-2">
                                Explore The Curriculum
                            </p>
                            <p className="mb-0">
                                A Project-based Curriculum crafted by Experts
                                for Kids.
                            </p>
                        </div>

                        <div className="d-flex flex-row align-items-center">
                            <a
                                href="/subjects/coding"
                                className="text-brand-accent open-sans-semi-bold mr-4"
                            >
                                Coding
                            </a>
                            <a
                                href="/subjects/design"
                                className="text-brand-accent open-sans-semi-bold"
                            >
                                Design
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="mb-3 mb-lg-4">
                            <img
                                style={{ maxWidth: '25px' }}
                                className="mb-3"
                                alt="price tag"
                                src={require('./assets/util-get-started/pricing-page.png')}
                            ></img>
                            <p className="open-sans-semi-bold mb-1">
                                Transparent, Simple Pricing
                            </p>
                            <p className="mb-0">
                                Always know what you will pay for. No Hidden
                                Fees.
                            </p>
                        </div>

                        <div>
                            <a
                                href="/pricing"
                                className="text-brand-accent open-sans-semi-bold"
                            >
                                Pricing
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UtilGetStarted;
