import React from 'react';

function HomeHeroSection() {
    return (
        <div>
            <div className="padding-y padding-x d-flex flex-column flex-lg-row justify-content-lg-center align-items-center">
                <div className="mb-5 mb-lg-0 max-width-400px mr-lg-5">
                    <h1 className="h2 mb-4">
                        Education for the{' '}
                        <span className="text-brand-accent d-block">
                            Real World
                        </span>
                    </h1>
                    <p className="mb-4 pb-2 pb-lg-3">
                        Astra Jr offers 1:1 private classes in practical
                        subjects like Coding and Digital Design - to nurture
                        learning for the real world.
                    </p>
                    <div>
                        <a
                            className="cta cta-accent p-slightly-small"
                            href="/trial"
                        >
                            Try A Free Class
                        </a>
                    </div>
                </div>

                <div className="ml-lg-5">
                    <picture>
                        <source
                            srcSet={require('./assets/home-hero-section/two-kids-d.webp')}
                            type="image/webp"
                        ></source>
                        <source
                            srcSet={require('./assets/home-hero-section/two-kids-d.png')}
                            type="image/png"
                        ></source>

                        <img
                            alt="two kids learning to code"
                            style={{ maxWidth: '100%', width: '500px' }}
                            src={require('./assets/home-hero-section/two-kids-d.png')}
                        ></img>
                    </picture>
                </div>
            </div>
        </div>
    );
}

export default HomeHeroSection;
