import React, { useState } from 'react';
import axios from 'axios';
import '../../../css/Footer.css';

function Footer() {
    const [subEmail, setSubEmail] = useState('');
    const [formLoading, setFormLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSubscribe = async () => {
        if (!subEmail) {
            document
                .querySelector('#footerSubmitButton')
                .classList.add('error-anim');
            return document
                .querySelector('#noSubEmailWarning')
                .classList.remove('d-none');
        } else {
            try {
                setFormLoading(true);
                const res = await axios.post('/api/mailinglist', {
                    parentEmail: subEmail
                });

                if (res.data.message === 'Success') {
                    setSubEmail('');
                    setSuccess(true);
                }
            } catch (error) {
                console.log(error);
                const backendWarningFooter = document.querySelector(
                    '#backendWarningFooter'
                );
                backendWarningFooter.textContent = 'Not a valid Email';
                backendWarningFooter.classList.remove('d-none');
            }
            setFormLoading(false);
        }
    };

    const returnButtonText = () => {
        if (formLoading) {
            return '....';
        }
        if (success) {
            return '👍';
        }
        return 'Subscribe';
    };

    return (
        <footer>
            <div className="d-flex flex-column flex-lg-row">
                <div className="col-12 col-lg-5 col-xl-4 padding-y px-5 background-dotted d-lg-flex align-items-lg-center justify-content-lg-end">
                    <div className="max-width-350px mr-lg-5">
                        <div className="mb-4">
                            <p className="h3 lora-regular">
                                Join our mailing list
                            </p>
                        </div>
                        <div className="mb-2">
                            <input
                                className="mb-2"
                                id="email"
                                type="email"
                                value={subEmail}
                                placeholder="Email"
                                onChange={(e) => setSubEmail(e.target.value)}
                                required
                            />
                            <p
                                id="noSubEmailWarning"
                                className="d-none p-extra-small mb-0 text-right text-danger"
                            >
                                Need your email
                            </p>
                        </div>

                        <button
                            onClick={onSubscribe}
                            id="footerSubmitButton"
                            className={
                                (subEmail === '' && success === false
                                    ? 'disabled'
                                    : '') +
                                ' p font-weight-bold mb-2 text-center'
                            }
                        >
                            {returnButtonText()}
                        </button>
                        <p
                            id="backendWarningFooter"
                            className="d-none p-extra-small mb-0 text-right text-danger"
                        ></p>
                    </div>
                </div>
                <div className="col-12 col-lg-7 padding-y px-lg-5 d-flex flex-row flex-wrap">
                    <div className="d-flex flex-column col-6 col-lg-5">
                        <a
                            className="mb-2 p-slightly-big my-4 links-with-border"
                            href="/"
                        >
                            Home
                        </a>
                        <a
                            className="mb-2 p-slightly-big my-4 links-with-border"
                            href="/trial"
                        >
                            Book A Trial
                        </a>
                        <a
                            className="mb-2 p-slightly-big my-4 links-with-border"
                            href="/pricing"
                        >
                            Pricing
                        </a>
                        <a
                            className="mb-2 p-slightly-big my-4 links-with-border"
                            href="/subjects/coding"
                        >
                            Coding for Kids
                        </a>
                        <a
                            className="mb-2 p-slightly-big my-4"
                            href="/subjects/design"
                        >
                            Digital Design for Kids
                        </a>
                    </div>
                    <div className="d-flex flex-column col-6 col-lg-5">
                        <a
                            className="mb-2 p-slightly-big my-4 links-with-border"
                            href="/faq"
                        >
                            FAQ
                        </a>
                        <a
                            className="mb-2 p-slightly-big my-4 links-with-border"
                            href="/reviews"
                        >
                            Reviews
                        </a>
                        <a
                            className="mb-2 p-slightly-big my-4 links-with-border"
                            href="/terms"
                        >
                            Terms
                        </a>
                    </div>
                </div>
            </div>

            <div className="px-4 px-lg-5 py-4 text-muted bg-light">
                <p className="mb-0 p-slightly-small">
                    Copyright © 2023 Astra Jr. All Rights Reserved.
                </p>
            </div>
        </footer>
    );
}

export default Footer;
