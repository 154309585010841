import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    PARENT_LOADED,
    STUDENTS_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    LOGIN_SETTINGS,
    SET_ERROR,
    SET_SUCCESS,
    CHANGE_ACTIVE_STUDENT
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        case SET_SUCCESS:
            return {
                ...state,
                error: null,
                success: action.payload,
                loading: false
            };
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                error: null
            };
        case PARENT_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                parent: action.payload,
                loading: false
            };
        case STUDENTS_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                studentData: action.payload,
                loading: false
            };
        // case REGISTER_FAIL:
        // case AUTH_ERROR:
        // case LOGIN_FAIL:
        //     return {
        //         ...state,
        //         token: null,
        //         isAuthenticated: false,
        //         parent: null,
        //         error: action.payload,
        //         loading: false
        //     };
        case CHANGE_ACTIVE_STUDENT:
            return {
                ...state,
                activeStudentIndex: action.payload
            };
        case LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                parent: null,
                loading: false
            };
        case LOGIN_SETTINGS:
            return {
                ...state,
                parent: action.payload,
                loading: false
            };
        default:
            return state;
    }
};
