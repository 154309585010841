import React, { Suspense, lazy } from 'react';
import '../../css/Track.css';
import Helmet from 'react-helmet';
import TrackHeroSection from 'ncomponents/subjects/TrackHeroSection';
import Nav from '../common-utils/Nav';

// Lazy import below the fold content
const UtilBenefitSection = lazy(() =>
    import('../common-utils/UtilBenefitSection')
);
const TrackCourseSectionCoding = lazy(() =>
    import('./TrackCourseSectionCoding')
);
const TrackTemplateProjectSection = lazy(() => import('./TrackProjectSection'));
const UtilReviewSection = lazy(() =>
    import('../common-utils/UtilReviewSection')
);
const TrackTrialSection = lazy(() => import('./TrackTrialSection'));
const UtilFaqSection = lazy(() => import('../common-utils/UtilFaqSection'));
const Footer = lazy(() => import('../../components/frontend/utils/Footer'));

function CodingTrack() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Coding Classes for Kids | Ages 6-18 | Astra Jr</title>
                <meta
                    name="description"
                    content="We offer Private 1:1 Online Coding Classes for Kids. Whether your child is an absolute beginner or an experienced programmer, Astra Jr can make a difference."
                />
                <script type="application/ld+json">
                    {`
                       {
                        "@context": "https://schema.org",
                        "@type": "Course",
                        "name": "Coding & Computer Science For Kids",
                        "description": "Learn how to code with private 1:1 classes by vetted instructors",
                        "provider": {
                          "@type": "Organization",
                          "name": "Astra Jr",
                          "sameAs": "https://www.astrajr.com"
                        },
                        "review": [{
                            "@type": "Review",
                            "reviewRating": {
                              "@type": "Rating",
                              "ratingValue": "5/5"
                            },
                            "author": {
                              "@type": "Person",
                              "name": "Amy Campbell"
                            },
                            "reviewBody": "From no programming backgroung to coding apps and websites, my son has come a long way. He is always excited for his coding classes."
                          },
                          {
                            "@type": "Review",
                            "reviewRating": {
                              "@type": "Rating",
                              "ratingValue": "5/5"
                            },
                            "author": {
                              "@type": "Person",
                              "name": "Lorena Williams"
                            },
                            "reviewBody": "Enrolled my kids with Astra Jr and they really make learning feel like child's play!"
                          }],
                          "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "4.8",
                            "bestRating": "5",
                            "ratingCount": "20"
                          }
                      }
                    `}
                </script>
            </Helmet>
            <div className="track track-coding">
                <Nav navActive={'Home'} banner={true} />
                <TrackHeroSection
                    heading={`Learn the language of the future: `}
                    headingFancy={`Coding for Kids`}
                    subHeading={`From self-driving cars to smart-phones, code is everywhere. Learn how to code with private 1:1 classes by vetted instructors.`}
                    img={`two-kids-f`}
                />
                <Suspense fallback={<div></div>}>
                    <UtilBenefitSection
                        heading={'Education for the Real World'}
                    />

                    <TrackCourseSectionCoding
                        subHeading={
                            'Whether your child is an absolute beginner or an experienced programmer, Astra Jr can make a difference.'
                        }
                    />
                    <TrackTemplateProjectSection
                        heading={'Projects by #AstraJr Kids'}
                        subHeading={
                            'We nurture the creativity of hundreds of ambitious kids across the globe'
                        }
                        imgFolder={`coding`}
                    />

                    <UtilReviewSection />

                    <TrackTrialSection
                        heading={`Try Astra Jr Classes for Free`}
                        subHeading={`Book a free class to see if it's a good fit for your
                child.`}
                        subHeadingNextLine={`Enroll only when you are sure.`}
                        ctaText={`Try Astra Jr For Free`}
                    />
                    <UtilFaqSection
                        faq={[
                            {
                                que: `How are Astrajr classes conducted?`,
                                ans: `The classes are 1-1 classes with a private
                                instructor. The classes are conducted online and
                                can be attended from the comfort of your home.`
                            },
                            {
                                que: `What is the setup required for the online
                                classes?`,
                                ans: `Just a couple of things - a laptop/desktop and
                                high speed internet (atleast 10 Mbps). We
                                recommend a pair of headphones/earphones for the
                                best virtual learning experience.`
                            },
                            {
                                que: `Can you tell me more about the free  class?`,
                                ans: `If you are on the fence about enrolling with us
                                - Book a free class and see if you would like to
                                continue. Enroll only when you are sure. The
                                trial class also helps us understand the
                                background of the student and plan for future
                                lessons.`
                            },
                            {
                                que: `What is the cost for the classes?`,
                                ans: (
                                    <React.Fragment>
                                        The pricing for the classes depends on
                                        the classes per week you choose to take.
                                        You can check out our{' '}
                                        <a
                                            href="/pricing"
                                            className="text-transform-none text-brand-accent"
                                        >
                                            pricing structure here.
                                        </a>
                                    </React.Fragment>
                                )
                            },
                            {
                                que: ` How is the schedule for the classes decided?`,
                                ans: `The classes are conducted on a schedule decided
                                by you. We are built for flexibility and we
                                accomodate requests for changes in schedule with
                                prior intimation.`
                            },
                            {
                                que: `What age should my child be for Astra Jr?`,
                                ans: ` We typically tutor students between Age 7-18.
                                However exceptions exist and we are open to
                                other age groups. We can conduct a free class
                                and ascertain if it's a good fit for your child.`
                            },
                            {
                                que: `What is the duration of the program?`,
                                ans: `We believe that students learn best when they decide the pace at which
                                they learn. Having said that, Most of our courses
                                have an average duration of 6-8 months.`
                            }
                        ]}
                    />
                    <Footer contact={false} whiteBG={true} />
                </Suspense>
            </div>
        </React.Fragment>
    );
}

export default CodingTrack;
