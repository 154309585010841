import React from 'react';
import '../../css/Dropdown.css';

function Dropdown() {
    return (
        <div className={'navigation-dropdown pl-5 py-5 '}>
            <ul className="px-0 d-flex flex-column">
                <li>
                    <a
                        className="subject-link d-flex align-items-center"
                        href="/subjects/coding"
                    >
                        <span className="mr-4">
                            <img
                                style={{ maxWidth: '30px' }}
                                alt="Subject - Coding"
                                src={require('../../ncomponents/home/assets/home-course-section/subject-coding.png')}
                            ></img>
                        </span>
                        <span className="d-flex flex-column">
                            <span className="subject-name open-sans-semi-bold mb-1 p">
                                Coding
                            </span>
                            <span className="subject-description p-small text-muted">
                                Learn the Languages of the Future
                            </span>
                        </span>
                    </a>
                </li>
                <div className="dotted-line my-4"></div>
                <li>
                    <a
                        href="/subjects/design"
                        className="subject-link d-flex align-items-center"
                    >
                        <span className="mr-4">
                            <img
                                style={{ maxWidth: '30px' }}
                                alt="Subject - Design"
                                src={require('../../ncomponents/home/assets/home-course-section/subject-design.png')}
                            ></img>
                        </span>

                        <span className="d-flex flex-column">
                            <span className="subject-name open-sans-semi-bold mb-1 p">
                                Digital Design
                            </span>
                            <span className="subject-description p-small text-muted">
                                Upskill for the Modern World{' '}
                            </span>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Dropdown;
