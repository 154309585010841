import React from 'react';

// const data = [
//     {
//         id: '01',
//         imgAlt: 'A schoolbag signifying one-one-one private classes',
//         imgSrc: 'private-classes.svg',
//         text1: 'Private',
//         text2: 'Classes'
//     },
//     {
//         id: '02',
//         imgAlt: 'A home-shaped clock signifying classes from the comfort of home',
//         imgSrc: 'homes-comfort.svg',
//         text1: `Home's`,
//         text2: 'Comfort'
//     },
//     {
//         id: '03',
//         imgAlt: 'A calendar signifying a flexibility of schedule',
//         imgSrc: 'flexible-schedule.svg',
//         text1: 'Flexible',
//         text2: 'Schedule'
//     },
//     {
//         id: '04',
//         imgAlt: 'A certificate signifying quality',
//         imgSrc: 'committed-tutors.svg',
//         text1: 'Committed',
//         text2: 'Tutors'
//     }
// ];
// const items = data.map((a) => {
//     return (
//         <div key={a.id} className="mx-3 mx-lg-5 my-2">
//             <img
//                 alt={a.imgAlt}
//                 className="icon"
//                 src={require(`./assets/track-hero-section/${a.imgSrc}`)}
//             ></img>
//             <p className="p-small line-height-small mt-2 mb-0">
//                 {a.text1}
//                 <br></br>
//                 {a.text2}
//             </p>
//         </div>
//     );
// });

function TrackHeroSection({ heading, headingFancy, subHeading, img }) {
    return (
        <>
            <div>
                <div className="padding-y padding-x d-flex flex-column flex-lg-row justify-content-lg-center align-items-center">
                    <div className="mb-5 mb-lg-0 max-width-400px mr-lg-5">
                        <h1 className="h2 mb-4">
                            {heading}{' '}
                            <span className="text-brand-accent d-block">
                                {headingFancy}
                            </span>
                        </h1>
                        <p className="mb-4 pb-2 pb-lg-3">{subHeading}</p>
                        <div>
                            <a
                                className="cta cta-accent p-slightly-small"
                                href="/trial"
                            >
                                Try Astra Jr For Free
                            </a>
                        </div>
                    </div>

                    <div className="ml-lg-5">
                        <picture>
                            <source
                                srcSet={require(`./assets/track-hero-section/${img}.webp`)}
                                type="image/webp"
                            ></source>
                            <source
                                srcSet={require(`./assets/track-hero-section/${img}.png`)}
                                type="image/png"
                            ></source>

                            <img
                                alt="two kids learning to code"
                                style={{ maxWidth: '100%', width: '500px' }}
                                src={require(`./assets/track-hero-section/${img}.png`)}
                            ></img>
                        </picture>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TrackHeroSection;
