import React, { Suspense, lazy } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import './App.css';
import AuthState from './context/auth/AuthState';
import ChildrenState from './context/children/ChildrenState';
import setAuthToken from './utils/setAuthToken';
import CodingTrack from './ncomponents/subjects/CodingTrack';
import Home from './ncomponents/home/Home';
import FourOFour from 'ncomponents/common-utils/FourOFour';
import UtilReviewSection from 'ncomponents/common-utils/UtilReviewSection';

// Blog Articles
import IsScratchEasyToLearn from 'ncomponents/blog/IsScratchEasyToLearn';

// const Curriculum = lazy(() => import('./components/frontend/Curriculum'));

import PrivateRoute from './components/routing/PrivateRoute';

// const Twilio = lazy(() => import('./components/utils/Twilio'));
const TwilioMus = lazy(() => import('./components/utils/TwilioMus'));
const Sms = lazy(() => import('./components/utils/Sms'));
const PricingPage = lazy(() => import('ncomponents/pricing/PricingPage'));
const Account = lazy(() => import('./ncomponents/checkout/Account'));
const FAQ = lazy(() => import('./ncomponents/faq/FAQ'));

// const CodingTrack = lazy(() => import('./ncomponents/subjects/CodingTrack'));
const DesignTrack = lazy(() => import('./ncomponents/subjects/DesignTrack'));
// const TrialForm = lazy(() => import('./ncomponents/trial/TrialForm'));

const Checkout = lazy(() => import('./ncomponents/checkout/Checkout'));

const DashboardHome = lazy(() => import('ncomponents/dashboard/DashboardHome'));
const DashboardCourse = lazy(() =>
    import('ncomponents/dashboard/DashboardCourse')
);
const DashboardCalendar = lazy(() =>
    import('ncomponents/dashboard/DashboardCalendar')
);
const DashboardParentHub = lazy(() =>
    import('ncomponents/dashboard/DashboardParentHub')
);
const AddNewCourse = lazy(() => import('ncomponents/dashboard/AddNewCourse'));
const UserLogin = lazy(() => import('ncomponents/user/UserLogin'));
const UserForgot = lazy(() => import('ncomponents/user/UserForgot'));
const UserReset = lazy(() => import('ncomponents/user/UserReset'));
// const CheckoutCustom = lazy(() =>
//     import('./ncomponents/checkout/CheckoutCustom')
// );

const ScratchJunior = lazy(() =>
    import('./ncomponents/course-page/ScratchJunior')
);
const ScratchLevel1 = lazy(() =>
    import('./ncomponents/course-page/ScratchLevel1')
);
const ScratchLevel2 = lazy(() =>
    import('./ncomponents/course-page/ScratchLevel2')
);
const AppDev = lazy(() => import('./ncomponents/course-page/AppDev'));
const RobloxLevel1 = lazy(() =>
    import('./ncomponents/course-page/RobloxLevel1')
);
const JavaScriptLevel1 = lazy(() =>
    import('./ncomponents/course-page/JavaScriptLevel1')
);
const JavaScriptLevel2 = lazy(() =>
    import('./ncomponents/course-page/JavaScriptLevel2')
);

const Blog = lazy(() => import('./ncomponents/blog/Blog'));

const Terms = lazy(() => import('./ncomponents/terms/Terms'));
const Reviews = lazy(() => import('./ncomponents/reviews/Reviews'));

if (localStorage.token) {
    setAuthToken(localStorage.token);
}
function App() {
    return (
        <React.Fragment>
            <AuthState>
                <ChildrenState>
                    <Router>
                        <Suspense fallback={<div></div>}>
                            <Switch>
                                <Route
                                    exact
                                    path="/dev"
                                    component={UtilReviewSection}
                                />

                                <Route
                                    exact
                                    path="/subjects/coding"
                                    component={CodingTrack}
                                />
                                <Route exact path="/" component={Home} />

                                <Route
                                    exact
                                    path="/subjects/design"
                                    component={DesignTrack}
                                />

                                {/* <Route
                                    exact
                                    path="/trial"
                                    component={TrialForm}
                                /> */}

                                <Route
                                    exact
                                    path="/pricing"
                                    component={PricingPage}
                                />

                                <Route
                                    exact
                                    path="/account"
                                    component={Account}
                                />

                                <Route
                                    exact
                                    path="/subjects/coding/scratch-junior"
                                    component={ScratchJunior}
                                />

                                <Route
                                    exact
                                    path="/subjects/coding/scratch-level-1"
                                    component={ScratchLevel1}
                                />

                                <Route
                                    exact
                                    path="/subjects/coding/scratch-level-2"
                                    component={ScratchLevel2}
                                />

                                <Route
                                    exact
                                    path="/subjects/coding/app-dev"
                                    component={AppDev}
                                />

                                <Route
                                    exact
                                    path="/subjects/coding/roblox-level-1"
                                    component={RobloxLevel1}
                                />

                                <Route
                                    exact
                                    path="/subjects/coding/javascript-level-1"
                                    component={JavaScriptLevel1}
                                />

                                <Route
                                    exact
                                    path="/subjects/coding/javascript-level-2"
                                    component={JavaScriptLevel2}
                                />

                                <Route exact path="/faq" component={FAQ} />

                                <Route exact path="/terms" component={Terms} />
                                <Route
                                    exact
                                    path="/reviews"
                                    component={Reviews}
                                />

                                <Route
                                    exact
                                    path="/trial/"
                                    component={Checkout}
                                />

                                <Route exact path="/blog" component={Blog} />

                                <Route
                                    exact
                                    path="/blog/is-scratch-easy-to-learn-a-beginners-guide-to-scratch-programming"
                                    component={IsScratchEasyToLearn}
                                />

                                {/* Dashboard Stuff */}

                                <Route
                                    exact
                                    path="/login"
                                    component={UserLogin}
                                />
                                <Route
                                    exact
                                    path="/forgot"
                                    component={UserForgot}
                                />
                                <Route
                                    exact
                                    path="/reset/:token"
                                    component={UserReset}
                                />

                                <PrivateRoute
                                    exact
                                    path="/dashboard/"
                                    component={DashboardHome}
                                />

                                <PrivateRoute
                                    exact
                                    path="/dashboard/course"
                                    component={DashboardCourse}
                                />

                                <PrivateRoute
                                    exact
                                    path="/dashboard/calendar"
                                    component={DashboardCalendar}
                                />

                                <PrivateRoute
                                    exact
                                    path="/dashboard/parent"
                                    component={DashboardParentHub}
                                />

                                <PrivateRoute
                                    exact
                                    path="/dashboard/parent/add-course"
                                    component={AddNewCourse}
                                />

                                {/* <Route exact path="/nem" component={Twilio} /> */}
                                <Route
                                    exact
                                    path="/nem/mus"
                                    component={TwilioMus}
                                />

                                <Route exact path="/sms" component={Sms} />

                                {/* For Google */}
                                <Route exact path="/tracks/coding">
                                    <Redirect to="/subjects/coding" />
                                </Route>
                                <Route exact path="/tracks/design">
                                    <Redirect to="/subjects/design" />
                                </Route>

                                <Route component={FourOFour} />
                            </Switch>
                        </Suspense>
                    </Router>
                </ChildrenState>
            </AuthState>
        </React.Fragment>
    );
}

export default App;
