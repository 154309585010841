import React, { Suspense, lazy } from 'react';
import '../../css/Home.css';
import Helmet from 'react-helmet';
import HomeHeroSection from './HomeHeroSection';
import Nav from '../common-utils/Nav';

// Lazy import below the fold content
const UtilAboutOurPrivateCourse = lazy(() =>
    import('../common-utils/UtilAboutOurPrivateCourse')
);
const UtilBenefitSection = lazy(() =>
    import('../common-utils/UtilBenefitSection')
);
const UtilHowItWorksSection = lazy(() =>
    import('../common-utils/UtilHowItWorksSection')
);
const HomeCourseSection = lazy(() => import('./HomeCourseSection'));
const FloatingSection = lazy(() => import('./FloatingSection'));

const UtilFiveStarReview = lazy(() =>
    import('../common-utils/UtilFiveStarReview')
);
const UtilReviewSection = lazy(() =>
    import('../common-utils/UtilReviewSection')
);

const UtilContactSection = lazy(() =>
    import('../common-utils/UtilContactSection')
);
const UtilFaqSection = lazy(() => import('../common-utils/UtilFaqSection'));
const UtilGetStarted = lazy(() => import('../common-utils/UtilGetStarted'));
const Footer = lazy(() => import('components/frontend/utils/Footer'));
// Unused
// const HomeParentSection = lazy(() => import('./HomeParentSection'));

function Home() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Astra Jr | 1:1 Private Online Classes for Kids</title>
                <meta
                    name="description"
                    content="Astra Jr offers 1:1 private classes for Kids in practical real-life subjects such as Coding, Design & more. Sign up for our classes and learn for the real world!"
                />
                <script type="application/ld+json">
                    {`{
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "https://www.astrajr.com/",
                            "logo": "https://www.astrajr.com/astrajr-logo.png"
                        }`}
                </script>
            </Helmet>
            <div className="home">
                <Nav navActive={'Home'} banner={true} />
                <HomeHeroSection />

                <Suspense fallback={<div></div>}>
                    {/* <UtilAboutOurPrivateCourse /> */}
                    <UtilBenefitSection
                        colorClasses={'bg-white'}
                        heading={'1:1 Private Online Classes'}
                    />

                    <HomeCourseSection />

                    <UtilHowItWorksSection colorClasses={'bg-white'} />

                    <FloatingSection />

                    <UtilReviewSection colorClasses={'bg-brand-light'} />

                    <UtilGetStarted />

                    <UtilFiveStarReview
                        colorClasses={'bg-brand-accent text-white'}
                        starImg={'pointed-star-light'}
                    />

                    {/* <HomeParentSection /> */}

                    <UtilContactSection
                        headText={'Need some help?'}
                        bgColorClass={'bg-brand-light'}
                    />

                    <UtilFaqSection
                        bgColorClass={'bg-light'}
                        faq={[
                            {
                                que: `How are Astrajr classes conducted?`,
                                ans: `The classes are 1-1 classes with a private
                                instructor. The classes are conducted online and
                                can be attended from the comfort of your home.`
                            },
                            {
                                que: `What is the setup required for the online
                                classes?`,
                                ans: `Just a couple of things - a laptop/desktop and
                                high speed internet (atleast 10 Mbps). We
                                recommend a pair of headphones/earphones for the
                                best virtual learning experience.`
                            },
                            {
                                que: `Can you tell me more about the free class?`,
                                ans: `If you are on the fence about enrolling with us
                                - Book a free class and see if you would like to
                                continue. Enroll only when you are sure. The
                                trial class also helps us understand the
                                background of the student and plan for future
                                lessons.`
                            },
                            {
                                que: `What is the cost for the classes?`,
                                ans: (
                                    <React.Fragment>
                                        The pricing for the classes depends on
                                        the classes per week you choose to take.
                                        You can check out our{' '}
                                        <a
                                            href="/pricing"
                                            className="text-transform-none text-brand-accent"
                                        >
                                            pricing structure here.
                                        </a>
                                    </React.Fragment>
                                )
                            },
                            {
                                que: ` How is the schedule for the classes decided?`,
                                ans: `The classes are conducted on a schedule decided
                                by you. We are built for flexibility and we
                                accomodate requests for changes in schedule with
                                prior intimation.`
                            },
                            {
                                que: `What age should my child be for Astra Jr?`,
                                ans: ` We typically tutor students between Age 7-18.
                                However exceptions exist and we are open to
                                other age groups. We can conduct a free class
                                and ascertain if it's a good fit for your child.`
                            },
                            {
                                que: `What is the duration of the program?`,
                                ans: `We believe that students learn best when they decide the pace at which
                                they learn. Having said that, Most of our courses
                                have an average duration of 6-8 months.`
                            }
                        ]}
                    />

                    <Footer />
                </Suspense>
            </div>
        </React.Fragment>
    );
}

export default Home;
