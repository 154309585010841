import React, { useReducer } from 'react';
import AuthContext from './authContext';
import authReducer from './authReducer';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    PARENT_LOADED,
    STUDENTS_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    CHANGE_ACTIVE_STUDENT,
    LOGOUT,
    LOGIN_SETTINGS,
    SET_ERROR,
    SET_SUCCESS
} from '../types';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const AuthState = (props) => {
    const initialState = {
        token: localStorage.getItem('token'),
        isAuthenticated: false,
        loading: true,
        parent: null,
        studentData: null,
        activeStudentIndex: localStorage.getItem('activeStudentIndex'),
        error: null,
        success: null
    };
    const [state, dispatch] = useReducer(authReducer, initialState);

    // Register Parent
    const register = async (formData) => {
        try {
            const res = await axios.post('/api/parent/register', formData);
            console.log(res.data);
            dispatch({ type: REGISTER_SUCCESS, payload: res.data });
            loadParent();
        } catch (error) {
            console.log(error.response.data.message);
            dispatch({ type: REGISTER_FAIL, payload: error.message });
        }
    };

    // Login Parent
    const login = async (formData) => {
        try {
            const res = await axios.post('/api/parent/login', formData);
            dispatch({ type: LOGIN_SUCCESS, payload: res.data });
            loadParent();
        } catch (error) {
            console.log(error.response.data.message);
            dispatch({
                type: LOGIN_FAIL,
                payload: error.response.data.message
            });
        }
    };

    // Load Parent from Token generated after form Submit
    const loadParent = async () => {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        try {
            const res = await axios.get('/api/parent/');
            // console.log(res.data);
            dispatch({ type: PARENT_LOADED, payload: res.data });
            loadStudentData();
            localStorage.setItem('activeStudentIndex', 0);
        } catch (error) {
            // console.log(error.response.data.message);
            dispatch({ type: AUTH_ERROR });
        }
    };

    // Load the student data
    const loadStudentData = async () => {
        try {
            const res = await axios.get('/api/parent/student');
            // console.log(res.data);
            dispatch({ type: STUDENTS_LOADED, payload: res.data });
        } catch (error) {
            // console.log(error.response.data.message);
            dispatch({ type: AUTH_ERROR });
        }
    };

    // Forgot Password
    const forgotPassword = async (formData) => {
        try {
            const res = await axios.post('/api/parent/forgot', formData);
            dispatch({ type: SET_SUCCESS, payload: res.data.message });
        } catch (error) {
            // console.log(error.response.data.message);
            dispatch({
                type: SET_ERROR,
                payload: error.response.data.message
            });
        }
    };

    // Reset Password
    const resetPassword = async (formData) => {
        try {
            const res = await axios.post('/api/parent/reset', formData);
            dispatch({ type: SET_SUCCESS, payload: res.data.message });
        } catch (error) {
            // console.log(error.response.data.message);
            dispatch({
                type: SET_ERROR,
                payload: error.response.data.message
            });
        }
    };

    // Change Active Student
    const changeActiveStudentIndex = (data) => {
        localStorage.setItem('activeStudentIndex', data);
        dispatch({ type: CHANGE_ACTIVE_STUDENT, payload: data });
    };

    // Logout Parent
    const logout = () => {
        dispatch({ type: LOGOUT });
    };

    // Change Parent's Login Settings
    const loginSettings = async (formData) => {
        try {
            const res = await axios.post(
                '/api/parent/settings/login',
                formData
            );
            console.log(res.data);
            dispatch({ type: LOGIN_SETTINGS, payload: res.data });
        } catch (error) {
            console.log(error.response.data.message);
            dispatch({ type: SET_ERROR });
        }
    };

    // Change Parent's Profile Settings
    const profileSettings = async (formData) => {
        try {
            const res = await axios.post(
                '/api/parent/settings/profile',
                formData
            );
            console.log(res.data);
            dispatch({ type: LOGIN_SETTINGS, payload: res.data });
        } catch (error) {
            console.log(error.response.data.message);
            dispatch({ type: SET_ERROR });
        }
    };

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuthenticated: state.isAuthenticated,
                loading: state.loading,
                parent: state.parent,
                studentData: state.studentData,
                activeStudentIndex: state.activeStudentIndex,
                error: state.error,
                success: state.success,
                register,
                login,
                loadParent,
                loadStudentData,
                logout,
                loginSettings,
                changeActiveStudentIndex,
                profileSettings,
                forgotPassword,
                resetPassword
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthState;
