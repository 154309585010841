import React from 'react';
import Helmet from 'react-helmet';
import Nav from './Nav';
import Footer from '../../components/frontend/utils/Footer';

function FourOFour() {
    return (
        <React.Fragment>
            <Helmet
                title={'Not Found'}
                meta={[{ name: 'ROBOTS', content: 'NOINDEX' }]}
            />
            <div>
                <Nav />
                <div className="padding-x padding-y bg-brand-light text-brand text-center">
                    <div className="my-4 my-lg-5">
                        <img
                            className="mb-2"
                            style={{ width: '100px' }}
                            src={require('./assets/four-o-four/404.svg')}
                            alt="404 Page not found"
                        ></img>
                    </div>
                    <div className="my-4 my-lg-5">
                        <h1 className="mb-4 h4">Page Not Found :/</h1>
                        <p className="mb-1">
                            Sorry, the page you were trying to view does not
                            exist.
                        </p>
                        <p>
                            <a
                                className="text-brand-accent"
                                href="mailto:hello@astrajr.com"
                            >
                                Email us
                            </a>{' '}
                            if you need any help or try heading back{' '}
                            <a className="text-brand-accent" href="/">
                                home.
                            </a>
                        </p>
                    </div>
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
}

export default FourOFour;
