export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const PARENT_LOADED = 'PARENT_LOADED';
export const STUDENTS_LOADED = 'STUDENTS_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CHANGE_ACTIVE_STUDENT = 'CHANGE_ACTIVE_STUDENT';
export const LOGIN_SETTINGS = 'LOGIN_SETTINGS';
export const SET_ERROR = 'SET_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';
// export const CLEAR_ERRORS = 'CLEAR_ERRORS';
// export const SET_ALERT = 'SET_ALERT';
// export const REMOVE_ALERT = 'REMOVE_ALERT';
// export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
// export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
// export const PASSWORD_RESET = 'PASSWORD_RESET';
// export const CLEAR_PASSWORD = 'CLEAR_PASSWORD';

export const GET_CHILDREN = 'GET_CHILDREN';
export const GET_LIVE_CLASS = 'GET_LIVE_CLASS';
export const NEW_CHILD = 'NEW_CHILD';
