import React, { useState, useEffect } from 'react';
import Dropdown from '../../components/frontend/Dropdown';
import '../../css/Nav.css';

// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import axios from 'axios';
// import Helmet from 'react-helmet';

function Nav({ navActive, noLogo, trialButton, noLinksOnlyLogo, banner }) {
    const [load, setLoad] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [pageScroll, setPageScroll] = useState(false);

    // const [formSubmitLoad, setFormSubmitLoad] = useState(false);
    // const [showPopup, setShowPopup] = useState(false);
    // const [userClosedPopup, setUserClosedPopup] = useState(false);
    // const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        setLoad(true);

        window.onscroll = function () {
            if (window.pageYOffset < 100) {
                setPageScroll(false);
            } else {
                if (window.pageYOffset > 800) {
                    // if (userClosedPopup !== true) {
                    //     setShowPopup(true);
                    // }
                }
                setPageScroll(true);
            }
        };
    }, []);
    // }, [userClosedPopup]);

    const toggleNav = () => {
        setToggle(!toggle);
    };

    // const [form, setForm] = useState({
    //     parentEmail: '',
    //     parentNumber: ''
    // });

    // const onChange = (e) => {
    //     setForm({ ...form, [e.target.name]: e.target.value });
    // };

    // const onPopupCloseBtn = () => {
    //     setShowPopup(false);
    //     setUserClosedPopup(true);
    // };

    // const sendForm = async () => {
    //     try {
    //         setFormSubmitLoad(true);
    //         console.log(form);
    //         const res = await axios.post('/api/offer', form);

    //         if (res.data.message === 'Form Submitted') {
    //             setForm({
    //                 parentEmail: '',
    //                 parentNumber: ''
    //             });
    //             setFormSubmitLoad(false);
    //             setFormSubmitted(true);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const onSubmit = (e) => {
    //     e.preventDefault();
    //     if (!form.parentEmail || !form.parentNumber) {
    //         return;
    //     } else {
    //         sendForm();
    //     }
    // };

    return (
        <React.Fragment>
            {/* The Banner */}
            {/* {banner === true ? (
                <div className="bg-brand-accent text-white px-4 px-lg-5 py-3 py-lg-4">
                    <div>
                        <h1 className="p-extra-small font-weight-bold mb-1">
                            Our New Year Offer is Live!
                        </h1>
                        <p className="p-extra-small mb-0">
                            Gift your child a skill for the future. Use code
                            'XMAS50' for a 50% discount.
                 
                        </p>
                    </div>
                </div>
            ) : (
                <></>
            )} */}

            <div
                className={
                    'nav-container d-flex align-items-center py-3 ' +
                    (pageScroll === true ? 'active ' : '')
                }
            >
                {/* Logo Container */}
                <div className="main-logo">
                    <a href="/">
                        <img
                            className={noLogo ? 'd-none' : 'logo-img'}
                            // srcSet={`${require('./assets/logos/astra-jr-logo-sm.png')} 300w, ${require('./assets/logos/astra-jr-logo-md.png')} 5000w`}
                            src={require('./assets/nav/astrajr-logo.png')}
                            alt="Astra Jr Logo"
                        ></img>
                    </a>
                </div>
                {noLinksOnlyLogo === true ? (
                    <></>
                ) : (
                    <>
                        <div
                            onClick={toggleNav}
                            data-active={toggle}
                            style={{ zIndex: '2' }}
                            className="d-md-none navigation-mob-button px-4 pt-3 pb-4"
                        ></div>
                        <nav
                            className={
                                'navigation-mob d-md-none p-4 ' +
                                (toggle ? '' : ' hide')
                            }
                        >
                            <section>
                                <p className="text-muted p-small mb-3">
                                    SUBJECTS
                                </p>

                                <ul className="px-0 d-flex">
                                    <li className="mr-5">
                                        <a
                                            className="d-flex align-items-center"
                                            href="/subjects/coding"
                                        >
                                            <span className="mr-2">
                                                <svg
                                                    style={{
                                                        width: '18px',
                                                        height: '18px'
                                                    }}
                                                    class="ProductIcon ProductIcon--Atlas "
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 40 40"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <title>Atlas logo</title>
                                                    <g clip-path="url(#product-icon-atlas-SiteMobileMenu-a)">
                                                        <path
                                                            d="M20.51.3c1 0 1.92.57 2.36 1.47l8.22 16.42 8.65 17.31a2.64 2.64 0 0 1-1.65 3.73l-.17.04c-.12.03-.24.04-.36.05h-.18L20.5 35.99 9.94 18.19l8.2-16.42A2.64 2.64 0 0 1 20.52.3z"
                                                            fill="#FB0"
                                                        ></path>
                                                        <path
                                                            d="M20.51.3c1 0 1.92.57 2.36 1.47l8.22 16.42L20.5 35.98 3.65 39.33a2.64 2.64 0 0 1-2.37-3.83l8.66-17.3 8.2-16.43A2.64 2.64 0 0 1 20.52.3z"
                                                            fill="url(#product-icon-atlas-SiteMobileMenu-b)"
                                                        ></path>
                                                        <path
                                                            d="M20.51.3c1 0 1.92.57 2.36 1.47l8.22 16.42L20.5 35.98 9.94 18.19l8.2-16.42A2.64 2.64 0 0 1 20.34.3h.18z"
                                                            fill="url(#product-icon-atlas-SiteMobileMenu-c)"
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <linearGradient
                                                            id="product-icon-atlas-SiteMobileMenu-b"
                                                            x1="16.03"
                                                            y1="18.01"
                                                            x2="15.94"
                                                            y2="39.33"
                                                            gradientUnits="userSpaceOnUse"
                                                        >
                                                            <stop stop-color="#FFD748"></stop>
                                                            <stop
                                                                offset=".54"
                                                                stop-color="#FFCD48"
                                                            ></stop>
                                                            <stop
                                                                offset="1"
                                                                stop-color="#FFCB48"
                                                            ></stop>
                                                        </linearGradient>
                                                        <linearGradient
                                                            id="product-icon-atlas-SiteMobileMenu-c"
                                                            x1="20.51"
                                                            y1="34.72"
                                                            x2="20.51"
                                                            y2="15.01"
                                                            gradientUnits="userSpaceOnUse"
                                                        >
                                                            <stop stop-color="#FFAD00"></stop>
                                                            <stop
                                                                offset="1"
                                                                stop-color="#FF7600"
                                                            ></stop>
                                                        </linearGradient>
                                                        <clipPath id="product-icon-atlas-SiteMobileMenu-a">
                                                            <path
                                                                fill="#fff"
                                                                d="M0 0h40v40H0z"
                                                            ></path>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span>Coding</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/subjects/design"
                                            className="d-flex align-items-center"
                                        >
                                            <span className="mr-2">
                                                <svg
                                                    style={{
                                                        width: '18px',
                                                        height: '18px'
                                                    }}
                                                    class="ProductIcon ProductIcon--PaymentLinks "
                                                    width="32"
                                                    height="27"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 32 27"
                                                >
                                                    <path
                                                        fill="url(#product-icon-payment-links-SiteMobileMenu-a)"
                                                        d="M1.00096 0C.296022 0-.187628.709839.0703092 1.3659L2.92789 8.6341c.09246.23517.09246.49663 0 .7318L.0703097 16.6341C-.187628 17.2902.296021 18 1.00096 18H17.6321c.8222 0 1.5605-.5031 1.8613-1.2682l2.7522-7c.1849-.47034.1849-.99325 0-1.4636l-2.7522-7C19.1926.503093 18.4543 0 17.6321 0h-5.9945c-.0204 0-.0407.00030844-.061.00092133C11.5622.00030919 11.5478 0 11.5333 0H1.00096z"
                                                    ></path>
                                                    <path
                                                        fill="url(#product-icon-payment-links-SiteMobileMenu-b)"
                                                        d="M1.00096 0C.296022 0-.187628.709839.0703092 1.3659L2.92789 8.6341c.09246.23517.09246.49663 0 .7318L.0703097 16.6341C-.187628 17.2902.296021 18 1.00096 18H17.6321c.8222 0 1.5605-.5031 1.8613-1.2682l2.7522-7c.1849-.47034.1849-.99325 0-1.4636l-2.7522-7C19.1926.503093 18.4543 0 17.6321 0h-5.9945c-.0204 0-.0407.00030844-.061.00092133C11.5622.00030919 11.5478 0 11.5333 0H1.00096z"
                                                        opacity=".25"
                                                    ></path>
                                                    <path
                                                        fill="#96F"
                                                        d="M10.001 9c-.70498 0-1.18863.70984-.93069 1.3659l2.85759 7.2682c.0925.2352.0925.4966 0 .7318l-2.85759 7.2682C8.81237 26.2902 9.29602 27 10.001 27h16.6311c.8222 0 1.5605-.5031 1.8613-1.2682l2.7522-7c.1849-.4703.1849-.9933 0-1.4636l-2.7522-7C28.1926 9.50309 27.4543 9 26.6321 9H10.001z"
                                                    ></path>
                                                    <path
                                                        fill="#0048E5"
                                                        d="M22.3843 9c0 .24831-.0463.49663-.1387.7318l-2.7522 7C19.1926 17.4969 18.4543 18 17.6321 18h-5.6349c0-.1242-.0231-.2483-.0693-.3659l-2.85759-7.2682C8.81237 9.70984 9.29602 9 10.001 9h12.3833z"
                                                    ></path>
                                                    <path
                                                        fill="url(#product-icon-payment-links-SiteMobileMenu-c)"
                                                        d="M10.001 9c-.70498 0-1.18863.70984-.93069 1.3659l2.85759 7.2682c.0925.2352.0925.4966 0 .7318l-2.85759 7.2682C8.81237 26.2902 9.29602 27 10.001 27h16.6311c.8222 0 1.5605-.5031 1.8613-1.2682l2.7522-7c.1849-.4703.1849-.9933 0-1.4636l-2.7522-7C28.1926 9.50309 27.4543 9 26.6321 9H10.001z"
                                                    ></path>
                                                    <defs>
                                                        <linearGradient
                                                            id="product-icon-payment-links-SiteMobileMenu-a"
                                                            x1="11.3775"
                                                            x2="11.2576"
                                                            y1=".293872"
                                                            y2="18.0004"
                                                            gradientUnits="userSpaceOnUse"
                                                        >
                                                            <stop
                                                                offset=".225577"
                                                                stop-color="#11EFE3"
                                                            ></stop>
                                                            <stop
                                                                offset="1"
                                                                stop-color="#21CFE0"
                                                            ></stop>
                                                        </linearGradient>
                                                        <radialGradient
                                                            id="product-icon-payment-links-SiteMobileMenu-b"
                                                            cx="0"
                                                            cy="0"
                                                            r="1"
                                                            gradientTransform="matrix(-7.0001 -13.49994 16.78746 -8.70477 14.033 13.5)"
                                                            gradientUnits="userSpaceOnUse"
                                                        >
                                                            <stop
                                                                offset=".09375"
                                                                stop-color="#fff"
                                                            ></stop>
                                                            <stop
                                                                offset="1"
                                                                stop-color="#fff"
                                                                stop-opacity="0"
                                                            ></stop>
                                                        </radialGradient>
                                                        <linearGradient
                                                            id="product-icon-payment-links-SiteMobileMenu-c"
                                                            x1="24.0333"
                                                            x2="11.648"
                                                            y1="16.5"
                                                            y2="5.7194"
                                                            gradientUnits="userSpaceOnUse"
                                                        >
                                                            <stop stop-color="#96F"></stop>
                                                            <stop
                                                                offset="1"
                                                                stop-color="#96F"
                                                                stop-opacity="0"
                                                            ></stop>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span>Digital Design</span>
                                        </a>
                                    </li>
                                </ul>
                            </section>
                            <div className="dotted-line my-4"></div>
                            <div className="d-flex flex-row">
                                <section className="mr-5">
                                    <ul className="px-0 d-flex flex-column">
                                        <li className="mb-3">
                                            <a
                                                href="/"
                                                className="d-flex align-items-center"
                                            >
                                                <span className="mr-2">
                                                    <img
                                                        alt="a home"
                                                        style={{
                                                            width: '17px'
                                                        }}
                                                        src={require('./assets/nav/home-page.png')}
                                                    />
                                                </span>
                                                <span>Home</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="d-flex align-items-center"
                                                href="/pricing"
                                            >
                                                <span className="mr-2">
                                                    <img
                                                        alt="a price tag"
                                                        style={{
                                                            width: '17px'
                                                        }}
                                                        src={require('./assets/nav/pricing-page.png')}
                                                    />
                                                </span>
                                                <span>Pricing</span>
                                            </a>
                                        </li>
                                    </ul>
                                </section>
                                <section>
                                    <ul className="px-0 d-flex flex-column">
                                        <li className="mb-3">
                                            <a
                                                href="/trial"
                                                className="d-flex align-items-center"
                                            >
                                                <span className="mr-2">
                                                    <img
                                                        alt="free trial"
                                                        style={{
                                                            width: '17px'
                                                        }}
                                                        src={require('./assets/nav/free-trial-page.png')}
                                                    />
                                                </span>
                                                <span>Free Trial</span>
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                href="/faq"
                                                className="d-flex align-items-center"
                                            >
                                                <span className="mr-2">
                                                    <img
                                                        alt="faq"
                                                        style={{
                                                            width: '17px'
                                                        }}
                                                        src={require('./assets/nav/faq-page.png')}
                                                    />
                                                </span>
                                                <span>FAQ</span>
                                            </a>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </nav>

                        {/* Desktop Navigation */}
                        <nav className="d-none d-md-flex navigation ">
                            <ul
                                data-active={load ? true : false}
                                className="transition-ladder d-flex flex-row p-0 mb-0"
                            >
                                <li className="nav-item no-underline mx-3 mx-md-4 transition-ladder-target d-flex flex-column align-items-center">
                                    <a
                                        href="/"
                                        className={
                                            'd-flex flex-column align-items-center px-1 pt-0 pb-3 text-uppercase'
                                        }
                                    >
                                        Subjects
                                    </a>
                                    <Dropdown />
                                </li>
                                <li className="nav-item mx-3 mx-md-4 transition-ladder-target d-flex flex-column align-items-center">
                                    <a
                                        className={
                                            'd-flex flex-column align-items-center px-1 pt-0 pb-3 text-uppercase ' +
                                            (pageScroll === true &&
                                            navActive === 'Trial'
                                                ? 'active'
                                                : '')
                                        }
                                        href="/trial"
                                    >
                                        Free Trial
                                    </a>
                                </li>
                                <li className="nav-item mx-3 mx-md-4 transition-ladder-target d-flex flex-column align-items-center">
                                    <a
                                        className={
                                            'd-flex flex-column align-items-center px-1 pt-0 pb-3 text-uppercase ' +
                                            (pageScroll === true &&
                                            navActive === 'Pricing'
                                                ? 'active'
                                                : '')
                                        }
                                        href="/pricing"
                                    >
                                        Pricing
                                    </a>
                                </li>
                                <li className="nav-item mx-3 mx-md-4 transition-ladder-target d-flex flex-column align-items-center">
                                    <a
                                        className={
                                            'd-flex flex-column align-items-center px-1 pt-0 pb-3 text-uppercase ' +
                                            (pageScroll === true &&
                                            navActive === 'FAQ'
                                                ? 'active'
                                                : '')
                                        }
                                        href="/faq"
                                    >
                                        FAQ
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        {trialButton === false ? (
                            <></>
                        ) : (
                            <div className="cta-nav d-none d-md-block">
                                <a
                                    className={
                                        'cta cta-accent p-extra-small text-uppercase ' +
                                        (pageScroll === true ? '' : 'd-none')
                                    }
                                    href="/trial"
                                >
                                    Book Trial
                                </a>
                            </div>
                        )}
                    </>
                )}
            </div>

            {/* <div
                className={
                    'popup-container d-flex flex-column flex-md-row ' +
                    (showPopup === true ? 'active' : '')
                }
            >
                <div onClick={onPopupCloseBtn} className="popup-close-btn">
                    <img
                        alt="close button"
                        style={{ width: '30px' }}
                        src={require('./assets/nav/close.png')}
                    ></img>
                </div>
                <div className="popup-pane-1 d-none d-md-block">
                    <img
                        alt="A girl using her tablet"
                        src={require('./assets/nav/popup-pane-1.png')}
                    ></img>
                </div>

                <div
                    // prettier-ignore
                    className={
                        (formSubmitted === true
                            ? 'd-none'
                            : 'd-block') +
                              ' popup-pane-2 px-5 py-4 bg-brand-light'
                    }
                >
                    <div className="mb-4 text-black text-center">
                        <h2 className="h3 mb-1">GET 50% OFF</h2>
                        <p className="p-extra-small">
                            First Month at Half Prices. 20 - 30 Nov.
                        </p>
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="mb-3 pb-1">
                            <input
                                id="parentEmail"
                                value={form.parentEmail}
                                required
                                type="email"
                                name="parentEmail"
                                placeholder="Email"
                                onChange={onChange}
                            ></input>
                        </div>
                        <div className="mb-3 pb-1">
                            <PhoneInput
                                country={'us'}
                                value={form.parentNumber}
                                enableSearch={true}
                                disableSearchIcon={true}
                                inputProps={{
                                    required: true
                                }}
                                onChange={(number) =>
                                    setForm({
                                        ...form,
                                        parentNumber: number
                                    })
                                }
                            />
                        </div>

                        <button
                            id="popupSubmit"
                            type="submit"
                            className="cta-accent text-capitalize open-sans-semi-bold d-flex justify-content-center"
                        >
                            <span
                                className={
                                    formSubmitLoad === true
                                        ? 'd-none'
                                        : 'd-block'
                                }
                            >
                                Send My Promo Code
                            </span>
                            <span
                                className={
                                    'loader ' +
                                    (formSubmitLoad === true
                                        ? 'd-inline-block'
                                        : 'd-none')
                                }
                            ></span>
                        </button>
                    </form>
                </div>
                <div
                    //prettier-ignore
                    className={
                        (formSubmitted === true
                            ? 'd-block'
                            : 'd-none') +
                              ' popup-pane-2 px-5 py-4 bg-brand-light'
                    }
                >
                    <div className="mb-4 text-black text-center">
                        <h2 className="h3 mb-1">GET 50% OFF</h2>
                        <p className="p-extra-small mb-4">
                            First Month at Half Prices. 2 - 5 Dec.
                        </p>
                        <p className="p mb-1 fancy-anim">
                            We've Emailed Your Code{' '}
                            <span role="img" aria-label="Balloon">
                                🎈
                            </span>
                        </p>
                        <p className="p-small text-muted">
                            PS: Don't forget to check your promotions folder.
                        </p>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    );
}

export default Nav;
