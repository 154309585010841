import React, { useEffect } from 'react';
import '../../css/UtilReviewSection.css';

const translateParent = (current, array, parent) => {
    parent.style.transform = `translateY(${array[current]})`;
};

const data = [
    {
        img: 'parent-1',
        parentName: 'Nathan',
        parentReview: `The classes are super flexible and convenient.`
    },
    {
        img: 'parent-2',
        parentName: 'Evelyn Smith',
        parentReview: `My child has a joyous time with Astra!`
    },
    {
        img: 'parent-3',
        parentName: 'Rajeev Singh',
        parentReview: `I like the focus on teaching syntax-based languages.`
    },
    {
        img: 'parent-4',
        parentName: 'Sunita Jain',
        parentReview: `Astra helped my daughter come out of her shell and talk more.`
    },
    {
        img: 'parent-5',
        parentName: 'Delisha',
        parentReview: `5/5. Professional Tutors and Fun Projects!`
    },
    {
        img: 'parent-6',
        parentName: 'Susan M.',
        parentReview: `The tutor is a perfect match for my child's learning needs.`
    }
];

function UtilReviewSection({ colorClasses }) {
    useEffect(() => {
        let array = ['0px', '-105px', '-230px', '-355px'];
        let current = 0;
        const parent = document.querySelector('.cycle-card-parent');

        setInterval(() => {
            if (current === 3) {
                current = 0;
                array = array.reverse();
            } else {
                current = current + 1;
            }
            translateParent(current, array, parent);
        }, 4000);
    }, []);

    return (
        <div className={'px-4 padding-y ' + (colorClasses ? colorClasses : '')}>
            <div className="d-flex flex-column flex-lg-row justify-content-center">
                <div className="max-width-500px mr-lg-5 mb-5 mb-lg-0">
                    <h2 className="h3 mb-4">
                        Reviews by Parents
                        <span className="text-brand-accent d-block">
                            Around the World
                        </span>
                    </h2>

                    <p>
                        We craft delightful courses for Kids across the world.
                        From Arizona to Bangalore - we have taught and mentored
                        hundreds of students!
                    </p>
                    <p className="mb-0">
                        Our 21st Century Approach to Modern Education ensures
                        Parents love their Classes. Hear from them.
                    </p>
                </div>
                <div className="ml-lg-5 cycle-card-container">
                    <div className="cycle-card-parent">
                        {data.map((a) => (
                            <div className="d-flex align-items-center cycle-card mb-4">
                                <picture>
                                    <source
                                        src={require(`./assets/util-review-section/${a.img}.webp`)}
                                        type="image/webp"
                                    ></source>
                                    <source
                                        src={require(`./assets/util-review-section/${a.img}.jpg`)}
                                        type="image/jpeg"
                                    ></source>
                                    <img
                                        style={{ width: '95px' }}
                                        src={require(`./assets/util-review-section/${a.img}.jpg`)}
                                        alt="A close up of a parent's face"
                                    ></img>
                                </picture>

                                <div className="ml-2 pr-1 pl-lg-1 cycle-card-text">
                                    <p className="p-extra-small mb-1">
                                        {a.parentReview}
                                    </p>
                                    <p className="p-extra-small mb-0">
                                        <span className="font-weight-bold">
                                            {a.parentName}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UtilReviewSection;
